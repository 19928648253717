<template>
  <section>
    <v-row>
      <!-- Viagem -->
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Disponibilidade para viagem</h3>
          <p>
            {{
              profissionais.viagem == "0"
                ? "Não disponível para viagem."
                : "Disponível para viagem."
            }}
          </p>
        </div>
      </v-col>
      <!-- Mudanca -->
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Disponibilidade para mudança</h3>
          <p>
            {{
              profissionais.mudanca == "0"
                ? "Não disponível para mudança."
                : "Disponível para mudança."
            }}
          </p>
        </div>
      </v-col>
      <!-- Qualificacoes -->
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Qualificações</h3>
          <p v-if="profissionais.qualificacoes">
            {{ profissionais.qualificacoes }}
          </p>
          <p v-else>Nenhuma qualificação</p>
        </div>
      </v-col>
      <!-- Habilitacoes -->
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Habilitações</h3>
          <v-chip-group
            v-if="
              profissionais.habilitacoes &&
              profissionais.habilitacoes.length > 0
            "
          >
            <v-chip
              small
              v-for="item in profissionais.habilitacoes"
              :key="item"
            >
              {{ item }}
            </v-chip>
          </v-chip-group>
          <p v-else>Não há nenhuma habilitação.</p>
        </div>
      </v-col>
      <!-- Redes Sociais -->
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Redes Sociais</h3>
          <v-list v-if="redesSociais && redesSociais.length > 0">
            <v-list-item two-line v-for="item in redesSociais" :key="item.link">
              <v-list-item-content>
                <v-list-item-title>{{ item.rede }}</v-list-item-title>
                <v-list-item-subtitle>
                  <a
                    :href="item.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link
                  </a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p v-else>Não há nenhuma rede social.</p>
        </div>
      </v-col>
      <!-- Idiomas -->
      <v-col cols="12" sm="6">
        <div class="d-flex flex-column">
          <h3>Idiomas</h3>
          <v-list v-if="idiomas && idiomas.length > 0">
            <v-list-item
              three-line
              v-for="item in idiomas"
              :key="item.descricao"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.descricao }}</v-list-item-title>
                <v-list-item-subtitle>
                  <strong>Tempo de experiência:</strong> {{ item.experiencia }}
                  {{
                    item.experiencia == 1
                      ? tempo[item.tempo]
                      : tempos[item.tempo]
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <strong>Proficiência:</strong>
                  {{ proficiencia[item.proficiencia] }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p v-else>Não há nenhum idioma.</p>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "InformacoesProfissionaisView",
  data() {
    return {
      tempo: {
        M: "Mês",
        A: "Ano",
      },
      tempos: {
        M: "Meses",
        A: "Anos",
      },
      proficiencia: {
        B: "Básico",
        I: "Intermediário",
        A: "Avançado",
      },
    };
  },
  computed: {
    profissionais: {
      get() {
        return this.$store.getters["resCandidatos/getProfissionais"];
      },
    },
    redesSociais: {
      get() {
        return this.$store.getters["resCandidatosRedesSociais/getRedesSociais"];
      },
    },
    idiomas: {
      get() {
        return this.$store.getters["resCandidatosIdiomas/getIdiomas"];
      },
    },
  },
};
</script>

<style></style>
