import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',[_vm._v("Disponibilidade para viagem")]),_c('p',[_vm._v(" "+_vm._s(_vm.profissionais.viagem == "0" ? "Não disponível para viagem." : "Disponível para viagem.")+" ")])])]),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',[_vm._v("Disponibilidade para mudança")]),_c('p',[_vm._v(" "+_vm._s(_vm.profissionais.mudanca == "0" ? "Não disponível para mudança." : "Disponível para mudança.")+" ")])])]),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',[_vm._v("Qualificações")]),(_vm.profissionais.qualificacoes)?_c('p',[_vm._v(" "+_vm._s(_vm.profissionais.qualificacoes)+" ")]):_c('p',[_vm._v("Nenhuma qualificação")])])]),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',[_vm._v("Habilitações")]),(
            _vm.profissionais.habilitacoes &&
            _vm.profissionais.habilitacoes.length > 0
          )?_c(VChipGroup,_vm._l((_vm.profissionais.habilitacoes),function(item){return _c(VChip,{key:item,attrs:{"small":""}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_c('p',[_vm._v("Não há nenhuma habilitação.")])],1)]),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',[_vm._v("Redes Sociais")]),(_vm.redesSociais && _vm.redesSociais.length > 0)?_c(VList,_vm._l((_vm.redesSociais),function(item){return _c(VListItem,{key:item.link,attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.rede))]),_c(VListItemSubtitle,[_c('a',{attrs:{"href":item.link,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" link ")])])],1)],1)}),1):_c('p',[_vm._v("Não há nenhuma rede social.")])],1)]),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',[_vm._v("Idiomas")]),(_vm.idiomas && _vm.idiomas.length > 0)?_c(VList,_vm._l((_vm.idiomas),function(item){return _c(VListItem,{key:item.descricao,attrs:{"three-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.descricao))]),_c(VListItemSubtitle,[_c('strong',[_vm._v("Tempo de experiência:")]),_vm._v(" "+_vm._s(item.experiencia)+" "+_vm._s(item.experiencia == 1 ? _vm.tempo[item.tempo] : _vm.tempos[item.tempo])+" ")]),_c(VListItemSubtitle,[_c('strong',[_vm._v("Proficiência:")]),_vm._v(" "+_vm._s(_vm.proficiencia[item.proficiencia])+" ")])],1)],1)}),1):_c('p',[_vm._v("Não há nenhum idioma.")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }